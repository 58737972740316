import { HTTP } from "../@core/HTTPS.service";
import { URL } from "../@core/serviceConfig";

export class UsersService {
  static async betaUsers(requestbody: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
  }) {
    try {
      const response = await HTTP.post(URL.betaUsers, requestbody);
      if (response) {
        return response.data;
      } else return undefined;
    } catch (error) {
      return error;
    }
  }
}
