import { Container, Grid } from "@mui/material";
// import mobileImg from "../../assets/mobile.png";
// import video from "../../../public/"
import "./style.css";
const VideoSection = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        width: "100%",
      }}
      style={{ padding: 0 }}
    >
      <Grid
        container
        style={{ backgroundColor: "#d6d6d6" }}
        justifyContent={"center"}
      >
        <Grid className="titleSection">
          <video className="video" controls autoPlay loop playsInline>
            <source src="./mobile_video.mp4" type="video/mp4" />
          </video>
        </Grid>
        {/* <Grid item xs={12} sm={4} className="imgSection">
        <img src={mobileImg} className="mobileImg" alt="mobile" />
      </Grid> */}
      </Grid>
    </Container>
  );
};
export default VideoSection;
