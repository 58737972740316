import { Button, Grid } from "@mui/material";
import { TicketProps } from "../TicketServiceSection";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./style.css";
import { useState } from "react";
const TicketServiceForMobile: React.FC<TicketProps> = ({
  leftHoverBox,
  rightHoverBox,
  leftBgColor,
  leftTitle,
  leftColor,
  rightBgColor,
  rightColor,
  rightTitle,
  bgColor,
}) => {
  const [showTopBox, setShowTopBox] = useState(false);
  const [showBottomBox, setShowBottomBox] = useState(false);
  return (
    <Grid container className="ticketContainer">
      {!showTopBox ? (
        <Grid
          xs={12}
          item
          className="buttonGrid"
          style={{ background: leftBgColor }}
        >
          <Button
            className="btn"
            fullWidth
            onClick={() => {
              setShowTopBox(!showTopBox);
              setShowBottomBox(false);
            }}
            endIcon={
              <ChevronRightIcon
                sx={{
                  color: leftColor,
                  borderRadius: "50%",
                  border: `2px solid ${leftColor}`,
                  fontSize: "1.5rem !important",
                  marginInlineStart: 3,
                }}
              />
            }
            sx={{
              color: leftColor,
            }}
          >
            {leftTitle}
          </Button>
        </Grid>
      ) : (
        <Grid
          display={"flex"}
          flexDirection="row"
          columnGap={!bgColor ? "1rem" : "0rem"}
          sx={{ backgroundColor: bgColor ? bgColor : leftBgColor }}
        >
          <ChevronLeftIcon
            onClick={() => setShowTopBox(!showTopBox)}
            sx={{
              color: bgColor ? "#000" : leftColor,
              borderRadius: "50%",
              border: bgColor ? `2px solid #000` : `2px solid ${leftColor}`,
              fontSize: "1.5rem !important",
              marginTop: 2,
              marginInlineStart: 3,

              // transform: showBottomBox ? "rotate(90deg)" : "",
            }}
          />
          {leftHoverBox}
        </Grid>
      )}
      {bgColor && <hr />}
      {!showBottomBox ? (
        <Grid
          xs={12}
          item
          className="buttonGrid"
          style={{ background: rightBgColor }}
        >
          <Button
            fullWidth
            className="btn"
            onClick={() => {
              setShowBottomBox(!showBottomBox);
              setShowTopBox(false);
            }}
            startIcon={
              <ChevronLeftIcon
                sx={{
                  color: rightColor,
                  borderRadius: "50%",
                  border: `2px solid ${rightColor}`,
                  fontSize: "1.5rem !important",
                  marginInlineEnd: 3,
                  // transform: showBottomBox ? "rotate(90deg)" : "",
                }}
              />
            }
            sx={{
              "& .MuiSvgIcon-root": {
                border: `2px solid ${rightColor}`,
              },
              color: rightColor,
              // paddingRight: "5rem",
            }}
          >
            {rightTitle}
          </Button>
        </Grid>
      ) : (
        <Grid
          display={"flex"}
          flexDirection="row"
          sx={{ backgroundColor: bgColor ? bgColor : rightBgColor }}
        >
          <ChevronLeftIcon
            onClick={() => setShowBottomBox(!showBottomBox)}
            sx={{
              color: bgColor ? "#000" : rightColor,
              borderRadius: "50%",
              border: bgColor ? `2px solid #000` : `2px solid ${rightColor}`,
              fontSize: "1.5rem !important",
              marginTop: 2,
              marginInlineStart: 3,
              // transform: showBottomBox ? "rotate(90deg)" : "",
            }}
          />
          {rightHoverBox}
        </Grid>
      )}
    </Grid>
  );
};

export default TicketServiceForMobile;
