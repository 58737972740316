import Footer from "../Footer";
import LogoSection from "../LogoSection";
import LogoTextSection from "../LogoTextSection";
import MemberSection from "../MemberSection/MemberSection";
import NewBetaForm from "../NewBetaForm/NewBetaForm";
import PremiumHoverBox from "../PremiumHoverBox";
import PremiumSectionForMobile from "../PremiumSectionForMobile";
import TicketHoverBox from "../TicketHoverBox";
import TicketSectionForMobile from "../TicketSectionForMobile";
import TicketServiceForMobile from "../TicketServiceForMobile";
import TicketServiceSection from "../TicketServiceSection";

const NewHomePage = () => {
  return (
    <>
      <LogoSection />
      <LogoTextSection />
      <TicketServiceSection
        leftHoverBox={<PremiumHoverBox />}
        rightHoverBox={<TicketHoverBox />}
        leftBgColor="#333333"
        leftColor="#ffffff"
        leftTitle="Your Premium Tickets"
        rightBgColor="#333333"
        rightColor="#ffffff"
        rightTitle="Our Premium Service"
      />
      <TicketServiceForMobile
        leftBgColor="#333333"
        leftColor="#ffffff"
        leftTitle="Your Premium Tickets"
        rightBgColor="#333333"
        rightColor="#ffffff"
        rightTitle="Our Premium Service"
        bgColor="#0bec81"
        leftHoverBox={<PremiumSectionForMobile />}
        rightHoverBox={<TicketSectionForMobile />}
      />
      <MemberSection />
      <NewBetaForm />
      <Footer rightSideImage={true} />
    </>
  );
};
export default NewHomePage;
