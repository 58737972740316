import { Box, Stack, Typography } from "@mui/material";
import sellerPhone from "../../assets/Phone_Seller.png";
import "./style.css";

const SellerSection = () => {
  return (
    <Box className="rightHoverBox">
      <Box className="sellerInnerBox">
        <Stack gap={"1rem"}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            // width={"80%"}
            gap={"2rem"}
            alignItems={"center"}
          >
            <img src={sellerPhone} alt="get price" className="getPriceImg" />
            <Typography className="sellerTitle">
              UNLOCK MAXIMUM VALUE
            </Typography>
          </Box>
          <Typography className="sellerDescription">
            Stop guessing on price. We bring you guaranteed offers from real
            buyers so that your seats are never left unsold, and money is never
            left on the table.
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};
export default SellerSection;
