import { Grid } from "@mui/material";
import mainLogo from "../../assets/offlogo-05.png";
import "./style.css";

const LogoSection = () => {
  let count = 0;

  function runTimeout() {
    setTimeout(() => {
      if (count > 2) {
        count = 0;
      }
      const body = document.getElementsByTagName("body")[0];
      body.style.setProperty("--fac", count.toString());
      count = count + 1;
      console.log(count);
      runTimeout();
    }, 3000);
  }

  runTimeout();
  return (
    <Grid container className="logo-section">
      <Grid item>
        <img src={mainLogo} alt="main_logo" className="mainLogo" />
      </Grid>
      {/* <Grid item display={"flex"} flexDirection={"row"} gap={2}>
        <Typography className="ticketsTitle">Your tickets</Typography> */}
      {/* <Typography className="termsTitle">Your terms</Typography> */}
      {/* <img className="logo" src={logo} alt="logo" /> */}

      {/* </Grid> */}
      <Grid item className="scrollingText">
        <section>
          <span className="textSpan">Your Tickets</span>
          <ul>
            <li>Your Terms</li>
            <li>Your Team</li>
            <li>Your Time</li>
          </ul>
        </section>
      </Grid>
    </Grid>
  );
};
export default LogoSection;
