import { Container, Grid } from "@mui/material";
import logo2 from "../../assets/logo-06.png";
import "./style.css";
const LogoTextSection = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        width: "100%",
      }}
      style={{ padding: 0 }}
    >
      <Grid container className="logo-text">
        <Grid item xs={4} sm={6} display={"flex"} alignItems={"center"}>
          <img className="imgLogo2" src={logo2} alt="logo2" />
        </Grid>
        <Grid item xs={8} sm={6} className="text-section">
          <span className="logoHeading">ELEVATE YOUR SEASON </span>
          <span className="subHeading">
            You made an investment to get the best seats in the house. Our
            service helps you make the most out of it.
          </span>
        </Grid>
      </Grid>
    </Container>
  );
};
export default LogoTextSection;
