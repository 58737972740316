import { Box, Grid, Stack, Typography } from "@mui/material";
import sellerPhone from "../../assets/Phone_Seller.png";
import "./style.css";

const SellerSectionForMobile = () => {
  return (
    <Grid className="innerBoxSeller">
      <Stack rowGap={"1rem"}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          // width={"83%"}
          gap={"1rem"}
          alignItems={"center"}
        >
          <img src={sellerPhone} alt="get price" className="sellerImg" />
          <Typography className="titleSeller">UNLOCK MAXIMUM VALUE</Typography>
        </Box>
        <Typography className="descriptionSeller">
          Stop guessing on price. We bring you guaranteed offers from real
          buyers so that your seats are never left unsold, and money is never
          left on the table.
        </Typography>
      </Stack>
    </Grid>
  );
};
export default SellerSectionForMobile;
