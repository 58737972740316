import { Box, Typography } from "@mui/material";
import "./style.css";

const PremiumSectionForMobile = () => {
  return (
    <Box className="premiumInner">
      <Box className="boxText">
        <Typography className="leftTitle">DEDICATED CONCIERGE</Typography>
        <Typography className="subTitleRight">
          Experience a white-glove service from start to finish,ensuring a
          seamless and premium ticket-buying and selling process.We'll be there
          to assist you with ticket selection,seating preferences, and any
          special requests.
        </Typography>
      </Box>
      <Box className="boxText">
        <Typography className="leftTitle">PREMIER ACCESS SWAP</Typography>
        <Typography className="subTitleRight">
          Receive exclusive access to inventory from other premier members.
        </Typography>
      </Box>
      <Box className="boxText">
        <Typography className="leftTitle">CUSTOM CORPORATE PACKAGES</Typography>
        <Typography className="subTitleRight">
          We work directly with organizations to optimize their corporate ticket
          portfolios. We'll find you extra seats when you need them - or make
          sure someone makes good use out of yours when you can't make it.
        </Typography>
      </Box>
    </Box>
  );
};
export default PremiumSectionForMobile;
