import React from "react";
import { Grid, Typography } from "@mui/material";
import playstoregoogle from "../../assets/icons/playstoregoogle.svg";
import appstoremobileimage from "../../assets/icons/appstoremobileimage.svg";
import "./index.css";

function BaseballSection() {
  return (
    <Grid className="baseball">
      <Grid container className="baseball-wrapper generic-flex">
        <Grid
          item
          xs={12}
          md={8}
          lg={8}
          className="text-section-left divider"
          display="flex"
          flexDirection="column"
        >
          <Typography variant="h2" className="approved-app">
            Offer Approved App is available for all devices
          </Typography>
          <Typography variant="h3" className="available-para-text">
            Offer Approved is available for all devices, consec tetur
            adipisicing elit. Itaque at harum quam icabo. Aliquam optio,
            delectus, dolorem quod neque.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          className="text-section-right generic-flex android-section"
        >
          <img
            src={playstoregoogle}
            className="playstore-icons"
            alt="playstore"
          />
          <img
            src={appstoremobileimage}
            className="appstore-icons text-section"
            alt="appstore"
          />
          <Typography className="android-devices-text">
            * Available on iPhone and all Android devices
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BaseballSection;
