import { Button, Container, Grid, Typography } from "@mui/material";
import "./style.css";
import NewInputField from "../NewInputField";
import { useState } from "react";
import { UsersService } from "../../services/api/user.service";
import { toast } from "react-toastify";

const NewBetaForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const [betaError, setBetaError] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setBetaError("");
    validateField(name, value);
  };

  const validateField = (name: string, value: string) => {
    let error = "";
    switch (name) {
      case "firstName":
        if (!value.trim()) {
          error = "First Name is required.";
        }
        break;
      case "lastName":
        if (!value.trim()) {
          error = "Last Name is required.";
        }
        break;
      case "email":
        if (!value.trim()) {
          error = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          error = "Invalid email format.";
        }
        break;
      case "phone":
        if (!value.trim()) {
          error = "Phone Number is required.";
        } else if (!/^\d+$/.test(value)) {
          error = "Phone No. must contain only numbers.";
        }
        break;
      default:
        break;
    }
    setFormErrors({ ...formErrors, [name]: error });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setBetaError("");
    const updatedFormErrors = {
      firstName: formData.firstName.trim() ? "" : "First Name is required.",
      lastName: formData.lastName.trim() ? "" : "Last Name is required.",
      email: formData.email.trim() ? "" : "Email is required.",
      phone: formData.phone.trim() ? "" : "Phone Number is required.",
    };
    const requestParams = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      phoneNumber: formData.phone,
      email: formData.email,
    };

    setFormErrors(updatedFormErrors);

    const hasErrors = Object.values(updatedFormErrors).some((error) => !!error);

    if (hasErrors) {
      console.log("Form has errors. Please check the fields.");
    } else {
      const toastId = toast.loading("Submitting form...");
      const response = await UsersService.betaUsers(requestParams);
      if (response.status) {
        setFormData({ firstName: "", lastName: "", email: "", phone: "" });
        toast.update(toastId, {
          render: "Form submitted successfully...",
          type: "success",
          isLoading: false,
          autoClose: 2500,
        });
      } else {
        setBetaError(response.response.data.message);
        toast.update(toastId, {
          render: response.response.data.message,
          type: "error",
          isLoading: false,
          autoClose: 2500,
        });
      }
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        width: "100%",
      }}
      style={{ padding: 0 }}
    >
      <Grid container className="formContainer">
        <Grid item className="formBox">
          <Typography className="formHeading">Request an invitation</Typography>
          <form className="formGroup">
            <Grid
              container
              justifyContent={"center"}
              columnSpacing={{ xs: 3, sm: 6 }}
              rowSpacing={{ xs: 2, sm: 4 }}
            >
              <Grid item xs={6} sm={6}>
                <label className="inputTitle">First Name</label>
                <NewInputField
                  name="firstName"
                  // label="First Name"
                  onChange={handleInputChange}
                  value={formData.firstName}
                  error={!!formErrors.firstName}
                  helperText={formErrors.firstName}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <label className="inputTitle">Last Name</label>
                <NewInputField
                  name="lastName"
                  // label="Last Name"
                  onChange={handleInputChange}
                  value={formData.lastName}
                  error={!!formErrors.lastName}
                  helperText={formErrors.lastName}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <label className="inputTitle">Email</label>
                <NewInputField
                  name="email"
                  // label="Email"
                  onChange={handleInputChange}
                  value={formData.email}
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <label className="inputTitle">Phone Number</label>
                <NewInputField
                  name="phone"
                  // label="Phone Number"
                  onChange={handleInputChange}
                  value={formData.phone}
                  error={!!formErrors.phone}
                  helperText={formErrors.phone}
                />
              </Grid>
            </Grid>
          </form>
          <Grid className="button-container">
            <Button
              className="submit-button"
              type="button"
              onClick={handleSubmit}
            >
              We will reach out shortly
            </Button>
            {betaError && (
              <Typography className="error_text" variant="body1">
                {betaError}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
export default NewBetaForm;
