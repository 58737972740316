import { Box, Grid, Typography } from "@mui/material";
import twitter from "../../assets/icons/twitter.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import instagram from "../../assets/icons/instagram.svg";
import footerSectionLogo from "../../assets/icons/footerSectionLogo.svg";
import mainlogo2 from "../../assets/icons/mainlogo2.png";
import "./index.css";
import { Link } from "react-router-dom";

function FooterSection() {
  function getCurrentYear() {
    return new Date().getFullYear();
  }
  return (
    <Grid className="main-footer-section">
      <Grid container className="footer-section">
        <Grid item xs={12} lg={8} md={6} className="footer-left-section">
          <img src={mainlogo2} alt="App Logo" className="app-logo"></img>
          <Typography className="footer-para">
            Offer Approved is available for all devices, consectetur adipisicing
            elit. Itaque at harum quam explicabo. Aliquam optio, delectus,
            dolorem quod neque eos totam.
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4} md={6} className="footer-right-section">
          <Typography className="text">Find us on:</Typography>
          <Box className="item-link">
            <Box className="social-link">
              <Link to="https://twitter.com/offer_approved" target="_blank">
                <img src={twitter} alt="Twitter" />
              </Link>
            </Box>
            /Offerapproved
          </Box>
          <Box className="item-link">
            <Box className="social-link">
              <Link
                to="https://www.linkedin.com/company/offerapproved/"
                target="_blank"
              >
                <img src={linkedin} alt="linkedin" />
              </Link>
            </Box>
            /Offerapproved
          </Box>
          <Box className="item-link">
            <Box className="social-link">
              <Link
                to="https://instagram.com/offerapproved?igshid=OGQ5ZDc2ODk2ZA=="
                target="_blank"
              >
                <img src={instagram} alt="instagram" />
              </Link>
            </Box>
            /Offerapproved
          </Box>
        </Grid>
        <Grid xs={12} md={12} lg={12} className={'fooler-down-text'}>
          <Grid xs={12} md={6} lg={6} className="footer-Copyrights">
            <Typography className="footer-para2">
              {` © Copyrights ${getCurrentYear()} All rights reserved.`} |{" "}
              <Link
                to={"/privacy-policy"}
                target="_blank"
                className="footer-link"
              >
                Privacy Policy
              </Link>{" "}
              |{" "}
              <Link
                to={"/terms-and-conditions"}
                target="_blank"
                className="footer-link"
              >
                Terms of Use
              </Link>
            </Typography>
          </Grid>
          <Grid xs={12} md={6} lg={6}>
            <Typography className="info">
              Download for:&nbsp;&nbsp; &nbsp;
              <strong>Android</strong>
              <strong>&nbsp; &nbsp; &nbsp; |&nbsp;&nbsp; &nbsp; iOS</strong>
            </Typography>
          </Grid>
        </Grid>
        <img
          src={footerSectionLogo}
          className="footer-logo"
          alt="Footer Logo"
        />
      </Grid>
    </Grid>
  );
}

export default FooterSection;
