import React from "react";
import { Container,Box, Typography, Link, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, tableCellClasses } from "@mui/material";
import Header from "../Header/Header";
import { List, ListItem } from "@mui/material";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import "./style.css";
interface IPrivacyPolicyProps {
  isShowHeader: boolean;
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const PrivacyPolicy = ({ isShowHeader }: IPrivacyPolicyProps) => {

 

  return (
    <Box className="privacy_body"> 
      {isShowHeader && <Header />}
      <Container sx={{ marginBlock: 10 }}>

      <Typography variant="h3" textAlign="center" mb={5} pb={4}>
      US Privacy Notice
      </Typography>
      <Typography variant="subtitle1" component="span" >
      Last updated on: October 8, 2023
      </Typography>
      <Typography variant="h5" component="h5" mt={3} className="boredr-top">
      The Purpose and Scope of this Notice
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      OfferApproved is committed to protecting your privacy and creating a safe and secure online environment for anyone who visits and buys on our Services.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      This privacy notice/policy <strong>(“Privacy Notice”)</strong> explains what type of personal information OfferApproved, LLC and its wholly owned subsidiaries (together, <strong>“OfferApproved”</strong>) collects from or about you when you access or visit our mobile applications (“<strong>Application</strong>”) and website located at <Link href="http://www.OfferApproved.com">www.OfferApproved.com</Link> (“<strong>Site</strong>”, and together with the Application, the <strong>“Services”</strong>), why and how we use it and the rights you have over your personal information.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      To better protect your personal information and privacy, we advise you to carefully read this privacy notice before accessing or using the Services.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      OfferApproved is referred to in this notice as “OfferApproved”, “we”, “us” or “our”, as the context requires. The term “you” (and “your”, as applicable) refers to you, the individual using the Services.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Personal Information We Collect
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Information you provide
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We collect personal information that you voluntarily provide to us when you create (and later modify) your user account for the Services, while you access and use the Services or interact or correspond with us or with other users of the Services.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      This may include, for example, your name, home and billing address, email, phone number, date of birth, user names combined with passwords, security questions plus answers, financial information (such as credit card or bank account number), free text notes, email correspondence with our customer support, favorite teams, shows or artists, purchase or viewing history, additional personal information which may be required in order to authenticate or identify you or the information you provide us with, etc. For users selling tickets on our marketplace, we collect the following information to complete payments: name, date of birth, social security number and/or tax identification numbers, address, and bank account information.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      In some situations, the information you provide may be considered sensitive data under applicable law. This includes any information you may provide concerning your disabilities, which we collect for purposes of providing appropriate access to and/or special seats at event premises.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Automatically-Collected Data
      </Typography>

      <Typography variant="subtitle1" component="p" mt={3} >
      When you access or use the Services, we automatically collect information about your visit, including:
      </Typography>

      <List>
        <ListItem>Log Information: We collect log information about your use of the Services, including the type of browser you use, access times, pages viewed, your IP address and the page you visited before navigating to the Services.</ListItem>
        <ListItem>Device Information: We collect information about the computer or mobile device you use to access our Services, including the hardware model, operating system and version, unique device identifiers and mobile network information.</ListItem>
        <ListItem>Your Content: With your permission, we collect information and content shared from other apps, such as the artists in your Apple music library, your Spotify playlists, or your name and profile picture from facebook or Spotify.</ListItem>
        <ListItem>Location Information: With your permission, we may collect information about the location of your device when you access or use the Services or otherwise consent to the collection of this information. For more details, please see “Your Choices” below.</ListItem>
        <ListItem>Information Collected by Cookies and Other Tracking Technologies: We use various technologies, including cookies and web beacons, to collect information concerning your online activities, such as the device and browser information, the pages you visit and the content you view. We may also use third-party analytics tools (such as Google Analytics) to obtain such information. For more details, please see “Information Collected by Cookies and Other Tracking Technologies” below.</ListItem>
      </List>

      <Typography variant="h5" component="h5" mt={3} >
      Information Collected by Cookies and Other Tracking Technologies
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We and our third-party partner and service providers use various technologies, including cookies, web beacons, pixels, and other similar tracking technologies when you interact with our websites or apps to collect information concerning your online activities, such as the device and browser information, the pages you visit and the content you view. This may include the use of session-recording technology to help us perform a number of functions, including to analyze purchase behavior and optimize our checkout process. We may also use third-party analytics tools (such as Google Analytics) to obtain such information.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      Cookies are small data files stored on your computer or portable device when you visit certain web pages. They are used as a mechanism for websites to remember things that a website browser has done in the past, for example, clicking on certain buttons or logging in at a previous time. Cookies help us improve the user experience and allow us to personalize our Services, assess which areas and features of our Services are popular and understand customers’ usage of the Services.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We also allow third parties and service providers to use online-tracking technologies on our websites and OfferApproved Mobile App for product analytics and advertising, which includes the management and display of advertisements specific to your interests. Third parties and service providers use this technology to provide advertising about products and services tailored to your interests on our websites, as well as those of third parties.
      </Typography>

      <Typography variant="h5" component="h5" mt={3} >
      Managing Cookies and “Do Not Sell”
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      Where these online tracking technologies are deemed to be a “sale” or “share” (which includes targeted advertising, as defined under the applicable laws) under one or more U.S. state privacy laws, you can opt-out of these online tracking technologies by submitting a request through the “Your Privacy Choices” link available at the bottom of the OfferApproved website and mobile app, or via the “Contact Us” section of our Privacy Notice below. While we will always honor your choices based on applicable privacy laws, this may result in the certain features in our website or app no longer being available.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      Please note that if you opt out through Your Privacy Choices, you will need to renew your opt-out choice if you visit our website from another device or browser, or if you clear your cookies because your request to opt out will be linked to your browser only.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      You can also manage cookies by activating the setting on your internet browser that allows you to refuse the setting of all or some cookies. Please refer to the instructions or the online help files available via your relevant browser if you wish to manage cookies in this way. Alternatively, please visit <Link href="http://www.allaboutcookies.org">www.allaboutcookies.org </Link> or <Link href="http://optout.aboutads.info/">http://optout.aboutads.info/</Link> for further information. To manage cookies relating to targeted advertising activities, you can visit  <Link href="http://www.youronlinechoices.com">www.youronlinechoices.com.</Link> To opt-out of being tracked by Google Analytics across all websites you can visit <Link href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout </Link>. If you are accessing our services from Canada, please visit: <Link href="http://www.aboutads.info"> http://www.aboutads.info </Link> or <Link href="https://youradchoices.ca">https://youradchoices.ca</Link>.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We may also disclose information, such as your email address, to our advertising partners so they can show you ads that are more relevant to your interests or market their products to you. To opt-out of “sales” or “sharing” of data, or “targeted advertising” associated with your email address or OfferApproved account, please complete the “Your Rights” form found in Your Privacy Choices.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Information We Collect From Other Source
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We may also obtain personal information from other sources and combine that with personal information we collect directly. For example, we may collect personal information about you from third parties, including but not limited to our partners, identity verification services, fraud detection service providers, credit bureaus, mailing list providers and publicly available sources.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      How We Use the Information We Collect
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We use the personal information we collect primarily to provide you with the products and services, to operate, personalize, maintain, support, host, enhance and improve the Services and your user experience, in order to meet our contractual obligations to others, comply with our obligations under applicable law, protect and exercise our legal rights, protect the security of our systems and the privacy of our customers, and otherwise protect or further our legitimate interests. Personal information may be stored or processed outside of your country of residence, in accordance with applicable laws and regulations.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We also use the personal information we collect to:
      </Typography>
      <List>
        <ListItem>detect, investigate and prevent fraudulent transactions and other illegal activities and protect the rights and property of OfferApproved and others;</ListItem>
        <ListItem>improve the user experience of our products and services;</ListItem>
        <ListItem>provide advertisements, content or features that match user profiles or interests;</ListItem>
        <ListItem>comply with legal obligations, court orders or other legal process and instructions of competent authorities;</ListItem>
        <ListItem>exercise or protect our rights in contract or by law (including in any dispute resolution process) or the rights of our users or business partners, including enforcing our terms of use and preventing or protecting against any illegal or authorized activities</ListItem>
      </List>

     
      <Typography variant="h5" component="h5" mt={3} >
      Sharing Your Personal Data
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We share your personal information as described below or as otherwise described in this notice:
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      OfferApproved’s Vendors and Providers
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      Many of the operations we perform require us to hire other companies to help us with these tasks. For example, OfferApproved retains companies to help us handle mail and email (including sending and delivering packages, postal mail and email), market our products and services, handle purchases of tickets to certain events, analyze user behavior, process credit card transactions, and provide customer service. The companies we retain to help us with these tasks have access to personal information to perform their functions, but may not use it for other purposes.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      OfferApproved’s Subsidiaries and Affiliates
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We may share your personal information with OfferApproved’s affiliates and subsidiaries, based on our legitimate interests. Our affiliates and subsidiaries may use your personal information solely for the purposes set out in the “How We Use the Information We Collect” paragraph above.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      External Service Providers, Event Providers, and OfferApproved Users
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      When you use the Services to purchase or sell tickets, we may provide personal information to the ticket seller, team, venue, promoters, league, or other third party involved in the sale of the ticket or otherwise related to the event or similar events in the future. We may even ask you to transact directly with that third-party vendor. For example, when you purchase a ticket, we may share your personal information with the seller of the ticket in order for the seller to complete the transaction. Similarly, when you purchase a ticket to an event (such as, but not limited to, a game or concert), we may provide your personal information to the teams you see, the league or organization that sanctioned the game, a concert promoter, and/or the operator of the venue or facility that hosted the event. Additionally, we may share information with third parties to assist in processing your payment with the seller, or a payment to you, if you are the seller. These third parties may use your personal information for analytics, offering you other products or services, preparation of reports, ticket fulfillment, as well as customer service and other legitimate business uses.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Third-Party Companies
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We may enter into agreements with companies (including, for example, by way of a co-branded or private-labeled website) (“Third-Party Companies”) that offer their products or services on our Services, and you may be asked to transact directly with those Third-Party Companies relating to your purchase of tickets. As a result, we may disclose your personal information to the Third-Party Companies that co-brand their products and services on our Services. For example, we do not process payment information, such as credit cards, directly, but instead utilize secure Third-Party Companies to process that payment. Because we do not control the privacy practices of Third-Party Companies, you should read and understand their privacy policies and/or notices.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Future Business Transfers
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      Our business is constantly changing. In the event OfferApproved goes through a business transition, such as a merger, acquisition by another company, or sale of all or a portion of its assets, your personal information will likely be among the assets transferred.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Compliance with Law and Protection of OfferApproved
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      OfferApproved reserves the right to disclose personal information in order to comply with the law. We may also disclose personal information to enforce or apply the terms and conditions applicable to our products and services, or protect the rights, property or safety of OfferApproved, our users, or others.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      De-Identified Data
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We may process and share data in an aggregated and anonymized form. When we do this, we ensure that the data we share cannot reasonably be used to identify you or your device.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Advertising and Analytics Services Provided by Others
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We may allow others to provide analytics services and serve advertisements on our behalf across the Internet and in applications. These entities may use cookies, web beacons, device identifiers and other technologies to collect information about your use of the Services and other websites and applications, including your IP address, web browser, mobile network information, pages viewed, time spent on pages or in apps, links clicked, information about your location, and conversion information. This information may be used by OfferApproved and others to, among other things, analyze and track data, determine the popularity of certain content, and to deliver advertising and content targeted to your interests on our Services and other websites and applications. For more information about interest-based ads, or to opt out of having your web browsing information used for interest-based advertising purposes, please visit <Link href="http://www.aboutads.info/choices">www.aboutads.info/choices.</Link> Your device may also include a feature that allows you to opt out of having certain information collected through applications used for interest-based advertising purposes. See “Your Choices” below for information about how to control the collection of location information by OfferApproved.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      How long do we keep your personal information for?
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We retain your personal information for no longer than is necessary for fulfilling the purposes set out in this notice and it will be stored securely as long as we have it. We apply different retention policies for different types of personal information. The retention period is dictated by certain factors, such as the type of personal information, the purpose for which the data was collected, the legal basis permitting us to collect and store it, statutory requirements, industry guidelines, our legitimate interests in providing the Services or protecting our legal rights, etc.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      When we no longer have a legitimate reason or legal requirement to process your personal information, we will delete it or store it in an anonymized and aggregated manner that no longer identifies you.
       </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      For example, if you close your account with us, we may retain in our files certain information associated with your account and past transactions for purposes of backups, fraud prevention, dispute resolution, investigations, and compliance with legal and regulatory requirements.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Your California Privacy Rights
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      California law permits residents of California to request certain details about how their information is used, stored, and shared. If you are a California resident, you may have certain rights. Specifically, the California Consumer Privacy Act (CCPA), as amended by the California Privacy Rights Act of 2020 (CPRA) (collectively, “<strong>California Privacy Laws</strong>”) may permit you to request that we: provide you the categories of personal information we have collected or disclosed about you; the categories of sources of such information; the business or commercial purpose for collecting your personal information; and the categories of third parties with whom we shared personal information. Additionally, it requires that we provide access to and/or a copy of certain information we hold about you, and, upon verified request, delete certain information we have about you. For more information on this and your rights under California Privacy Laws, please see our California Privacy Notice.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Additional Information Relating to Users Located in the UK and/or European Union
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      If you are located within the UK/EU, the following information applies to you:
      </Typography>
      <List>
        <ListItem>In collecting your personal information, we are acting as a data controller under applicable UK/EU privacy laws.</ListItem>
        <ListItem>Unless we otherwise notify you, we do not use the personal information you provide to make any automated decisions that might affect you.</ListItem>
        <ListItem>We will let you know when we collect your personal information if there is a statutory or contractual requirement to provide the personal information.</ListItem>
        <ListItem>Applicable UK/EU privacy laws give you the right to ask us what personal information we hold about you, and you can also ask us to correct it if it is inaccurate. You can also ask your personal information to be erased and you can ask for us to give you a copy of the information. Under certain circumstances, you also have a right to object to the collection or processing of your personal information or restrict its processing. If you wish to access, correct, erase or restrict or object to the collection or processing of your personal information held by us, please contact us via Contact Us information below.</ListItem>
        <ListItem>Where processing of personal information is based on your consent, you have the right to withdraw consent at any time for future processing.</ListItem>
        <ListItem>If you have a complaint about our use of your personal information, you have a right to lodge a complaint with an applicable data protection authority.</ListItem>
        <ListItem>We process and retain your personal information on servers located in the U.S. and in other geographical territories.</ListItem>
        <ListItem>As set out in this notice, we may share your personal information with third parties, who act as our processors, in which case we will ensure that our processors assume the applicable data protection obligations we are subject to under applicable UK/EU data protection laws, in particular if any processing is carried out outside the UK or EU. For transfers of personal information outside the UK or EU we will put in place appropriate contracts to protect the personal information. You may request further information on this by contacting us via the Contact Us information below.</ListItem>
        <ListItem>We have designated a data protection officer to monitor our compliance with this notice and applicable UK/EU privacy laws. The data protection officer can be contacted via the Contact Us information below.</ListItem>
        <ListItem>Where we process your personal information based on our legitimate interest, as set out in this notice, we will only do so where the processing is relevant, adequate and limited to the minimum required in order to achieve the purpose for which the information is collected.</ListItem>
        <ListItem>Please note, that although the Privacy Shield framework (the European Union-United States and Swiss-U.S. Privacy Shield self-certification program operated by the United States Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union and Switzerland to the United States) (“Privacy Shield”) was declared invalid by the European Union Court of Justice, OfferApproved is committed to protecting your privacy and continues to adhere to its obligations under the Privacy Shield. OfferApproved complies with the Privacy Shield framework and OfferApproved has certified to the U.S. Department of Commerce that it adheres to the Privacy Shield Principles. If there is any conflict between the terms in this privacy notice and the Privacy Shield Principles, the Privacy Shield Principles shall govern to the extent that the Privacy Shield Principles are more favorable to you. To learn more about the Privacy Shield program, and to view our certification, please visit <Link href="https://www.privacyshield.gov/">https://www.privacyshield.gov/</Link>.</ListItem>
        <ListItem>OfferApproved has further committed to refer unresolved Privacy Shield complaints to ICDR-AAA EU-U.S. Privacy Shield and Swiss-U.S. Privacy Shield Program Independent Recourse Mechanism (IRM), an alternative dispute resolution provider located in the United States. If you do not receive timely acknowledgment of your complaint from us, or if we have not addressed your complaint to your satisfaction, please visit <Link href="https://go.adr.org/privacyshield.html">https://go.adr.org/privacyshield.html</Link> for more information or to file a complaint. The services of ICDR-AAA are provided at no cost to you.</ListItem>
        <ListItem>In accordance with Privacy Shield, OfferApproved (i) is subject to the investigatory and enforcement powers of the Federal Trade Commission (FTC), (ii) has the requirement to disclose personal information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements (iii) has further committed to the possibility, under certain conditions, of individuals to invoke binding arbitration, and (iv) under certain circumstances may be liable in cases of onward transfers to third parties.</ListItem>
      </List>
      <Typography variant="h5" component="h5" mt={3} >
      Your Choices
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      How You Can Access or Change Your Personal Data
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      OfferApproved understands that you may want to change or access your Personal Data. To change your Personal Data, sign in and go to your “My Account” page.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Email
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      If you would prefer not to receive notices of special savings or promotions from OfferApproved, you may simply opt-out from receiving them by clicking the “unsubscribe” link included within any email and/or by updating your notifications preferences in your “My Account” page. Please note that, even if you opt out of promotional emails, we may still send you important service-related communications regarding your account and tickets you may have purchased.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Location Information
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      You may be asked to consent to the Service’s collection of location information. Even if you consent to the collection of your location information, you can subsequently stop the collection of this information at any time by changing the preferences on your mobile device. You may also stop our collection of location information by following the standard uninstall process to remove all of our mobile applications from your device.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      When we ask to collect your location information, we will only do so with your consent and limit the processing of this information in order to provide you with the specific location-based services, including, but not limited to, providing you with directions to an event or helping you utilize third-party ride-sharing services. In these contexts, it may be necessary to share this information with third parties at your request.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Connections and Links to Other Services and Applications
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      The Services may include links and connections to third-party services and applications. While we only partner with trusted third-parties, we have no control over and are not responsible for the information that you submit to, or is collected or processed by such third-party services or applications and this privacy notice does not cover any data collection or processing by such third-party services or applications. You are encouraged to carefully read the privacy policies, notices, and the terms of use of those third-party services or applications to understand their procedures for collecting, using, and disclosing personal information.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Children’s Privacy
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      At OfferApproved, we do not intentionally collect or process personal information from children under the age of thirteen (13). If a child under 13 submits personal information to OfferApproved and we learn that the information is from a child under 13, we will attempt to delete the information as soon as possible. If you are a parent or guardian and you believe we have collected personal information from your child under 13, please contact us via the Contact Us information below.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      Please note that the age threshold mentioned above may vary depending on applicable legal requirements in your jurisdiction. If this is the case, we will indicate it accordingly at the time of collection.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Amendments to this Privacy Notice
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      If OfferApproved decides to change our Privacy Notice, we will post the updated notice within the Application, on this page, and/or the homepage of the Site, and/or other places we deem appropriate. We reserve the right to modify this Privacy Notice at any time, so please review it frequently. If we make material changes to this Privacy Notice, we will notify you here, and/or by email, and/or by means of a notice on our homepage.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Contact Us
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We care about your personal information and your rights. If you have any questions or comments about this notice, the ways in which OfferApproved collects and uses your information as described here, your choices and rights regarding such use, or wish to exercise your rights under law, please do not hesitate to contact us at:
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      Email <Link href="mailto:hello@OfferApproved.com">hello@OfferApproved.com</Link> 
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      Website: <Link href="http://www.OfferApproved.com">www.OfferApproved.com </Link> 
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      Postal Address: 
      </Typography>
      <Typography variant="subtitle1" component="p" mt={2} >
      OfferApproved, LLC
      </Typography>
      <Typography variant="subtitle1" component="p"  >
      Attn: Legal Department
      </Typography>
      <Typography variant="subtitle1" component="p"  >
      1360 Post Oak Blvd, Suite 2400 
      </Typography>
      <Typography variant="subtitle1" component="p"  >
      Houston, TX 77056
      </Typography>


      <Typography variant="h3" textAlign="center" mb={5} pb={5} mt={5} pt={5} >
      California Privacy Notice
      </Typography>
      <Typography variant="subtitle1" component="span">
      Last updated on: October 1, 2023
      </Typography>
      <Typography variant="h5" component="h5" mt={3} className="boredr-top">
      OfferApproved Privacy Notice for California Residents
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      This California Privacy Notice is provided as a supplement to our OfferApproved Privacy Notice and includes information on how California Residents can exercise their rights under the California Consumer Privacy Act of 2018 (CCPA), as amended by the California Privacy Rights Act of 2020 (CPRA) (collectively, “<strong>California Privacy Laws</strong>”).
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      This California Privacy Notice applies solely to visitors, users, and others who reside in the State of California (“consumers” or “you”). Any terms defined in the California Privacy Laws have the same meaning when used in this notice. All terms not defined in this California Privacy Notice for California Residents shall have the definition found in OfferApproved’s Privacy Notice. In the event of a conflict between any other OfferApproved policy, statement or notice and this California Privacy Notice this notice shall prevail as to California residents’ rights under California Privacy Laws.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Information We Collect
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We collect information that identifies, relates to, describes, references, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer, household, or device (“<strong>personal information</strong>”). We may collect the following categories of personal information from you:
      </Typography>


      <Box my={5}>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell >Category</StyledTableCell>
            <StyledTableCell >Examples</StyledTableCell>
            <StyledTableCell >Collected</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <StyledTableRow >
              <StyledTableCell component="th" scope="row">A. Identifiers </StyledTableCell>
              <StyledTableCell >A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, or other similar identifiers.</StyledTableCell>
              <StyledTableCell component="th" scope="row">Yes</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell component="th" scope="row">B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)) </StyledTableCell>
              <StyledTableCell >A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, precise geolocation, contents of communications, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.</StyledTableCell>
              <StyledTableCell component="th" scope="row">Yes</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell component="th" scope="row">C. Protected classification characteristics under California or federal law </StyledTableCell>
              <StyledTableCell >Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</StyledTableCell>
              <StyledTableCell component="th" scope="row">Yes</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell component="th" scope="row">D. Commercial information </StyledTableCell>
              <StyledTableCell >Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</StyledTableCell>
              <StyledTableCell component="th" scope="row">Yes</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell component="th" scope="row">E. Biometric information </StyledTableCell>
              <StyledTableCell >Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</StyledTableCell>
              <StyledTableCell component="th" scope="row">No</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell component="th" scope="row">F. Internet or other similar network activity </StyledTableCell>
              <StyledTableCell >Browsing history, search history, information on a consumer's interaction with a website, application, or advertisement.</StyledTableCell>
              <StyledTableCell component="th" scope="row">Yes</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell component="th" scope="row">G. Geolocation data </StyledTableCell>
              <StyledTableCell >Physical location or movements.</StyledTableCell>
              <StyledTableCell component="th" scope="row">Yes</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell component="th" scope="row">H. Sensory data </StyledTableCell>
              <StyledTableCell >Audio, electronic, visual, thermal, olfactory, or similar information.</StyledTableCell>
              <StyledTableCell component="th" scope="row">No</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell component="th" scope="row">I. Professional or employment-related information </StyledTableCell>
              <StyledTableCell >Current or past job history or performance evaluations.</StyledTableCell>
              <StyledTableCell component="th" scope="row">Yes</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell component="th" scope="row">J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)) </StyledTableCell>
              <StyledTableCell >Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.</StyledTableCell>
              <StyledTableCell component="th" scope="row">No</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow >
              <StyledTableCell component="th" scope="row">K. Inferences drawn from other personal information </StyledTableCell>
              <StyledTableCell >Information regarding interactions with our Services, advertising preferences, and communications with us; profiles reflecting a person's preferences, characteristics, psychological trends, predispositions, propensities, behavior, attitudes, intelligence, abilities, and aptitudes, including categories in which a user may fall; information about activities and interactions with our advertising partners including data about the advertisements were shown, how often they were shown, when and where they were shown, and whether any action was taken, such as clicking on an advertisement or making a purchase.</StyledTableCell>
              <StyledTableCell component="th" scope="row">Yes</StyledTableCell>
            </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
      </Box>

      <Typography variant="h5" component="h5" mt={3} >
      Sources of Personal Information that we Collect
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We receive personal information from a variety of sources, including directly from you (the user), your devices, and our partners. The categories of sources from which we collect personal information includes: you, your use of OfferApproved’s Services, your verbal or written correspondence with us, your use of our partners’ services or websites, our affiliates, advertisers, data brokers, social media, public databases, and other third parties.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Categories of Information Disclosed
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We have disclosed the following categories of personal information of California consumers for legitimate business purposes:
      </Typography>
      <List>
        <ListItem>Category A: Identifiers</ListItem>
        <ListItem>Category B: Categories of personal information described in Cal. Civ. Code §1798.80(e)</ListItem>
        <ListItem>Category D: Commercial information</ListItem>
        <ListItem>Category F: Network Activity</ListItem>
        <ListItem>Category G: Geolocation data</ListItem>
        <ListItem>Category K: Inferences</ListItem>
      </List>
      <Typography variant="h5" component="h5" mt={3} >
      Opting Out of Cookies and Sale or Share of Your Data
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We and our third-party partner and service providers use various technologies, including cookies, web beacons, pixels, and other similar tracking technologies when you interact with our websites or apps to collect information concerning your online activities, such as the device and browser information, the pages you visit and the content you view. We may also use third-party analytics tools (such as Google Analytics) to obtain such information.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      Cookies are small data files stored on your computer or portable device when you visit certain web pages. They are used as a mechanism for websites to remember things that a website browser has done in the past, for example, clicking on certain buttons or logging in at a previous time. Cookies help us improve the user experience and allow us to personalize our Services, assess which areas and features of our Services are popular and understand customers' usage of the Services.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We also allow third parties and service providers to use online-tracking technologies on our websites and OfferApproved Mobile App for product analytics and advertising, which includes the management and display of advertisements specific to your interests. Third parties and service providers use this technology to provide advertising about products and services tailored to your interests on our websites, as well as those of third parties.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Managing Cookies and “Do Not Sell”
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      Where these online tracking technologies are deemed to be a “sale” or “share” (which includes targeted advertising, as defined under the applicable laws) under one or more U.S. state privacy laws, you can opt-out of these online tracking technologies by submitting a request through the “Your Privacy Choices” link available at the bottom of the OfferApproved website and mobile app, or via the “Contact Us” section of our Privacy Notice below. While we will always honor your choices based on applicable privacy laws, this may result in the certain features in our website or app no longer being available.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      Please note that if you opt out through Your Privacy Choices, you will need to renew your opt-out choice if you visit our website from another device or browser, or if you clear your cookies because your request to opt out will be linked to your browser only.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      You can also manage cookies by activating the setting on your internet browser that allows you to refuse the setting of all or some cookies. Please refer to the instructions or the online help files available via your relevant browser if you wish to manage cookies in this way. Alternatively, please visit <Link href="http://www.allaboutcookies.org">www.allaboutcookies.org</Link> or <Link href="http://optout.aboutads.info/">http://optout.aboutads.info/</Link> for further information. To manage cookies relating to targeted advertising activities, you can visit <Link href="http://www.youronlinechoices.com">www.youronlinechoices.com</Link>. To opt-out of being tracked by Google Analytics across all websites you can visit <Link href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</Link>. If you are accessing our services from Canada, please visit: <Link href="http://www.aboutads.info">http://www.aboutads.info </Link>or <Link href="https://youradchoices.ca">https://youradchoices.ca</Link>.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We may also disclose information, such as your email address, to our advertising partners so they can show you ads that are more relevant to your interests or market their products to you. To opt-out of “sales” or “sharing” of data, or “targeted advertising” associated with your email address or OfferApproved account, please complete the “Your Rights” form found in “Your Privacy Choices.”
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Your Rights and Choices
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      California Privacy Laws provide consumers (California residents) with specific rights regarding their personal information. This section describes your rights under California Privacy Laws and explains how to exercise those rights.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Access to Specific Information and Data Portability Rights
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      You have the right to request that we disclose certain information to you about our collection and use of your personal information.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Deletion Request Rights
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Exercising Access, Data Portability, and Deletion Rights
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by emailing us at <Link href="mailto:hello@OfferApproved.com">hello@OfferApproved.com</Link>.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We care about your rights under California Privacy Laws and will do our best to assist you in exercising these rights.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
    <strong>
   <i> Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related to your personal information.</i>
    </strong>
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      If you wish to submit a request to know, delete, or opt out through an authorized agent, we require the following before we can process the request:
      </Typography>
      <List>
        <ListItem>A notarized copy of your written permission authorizing the agent to make the request, and</ListItem>
        <ListItem>That you verify your identity directly with us.</ListItem>
        <ListItem>Direct confirmation from you that you provided the agent permission to submit the request.</ListItem>
      </List>
      <Typography variant="subtitle1" component="p" mt={3} >
      If your authorized agent has a valid power of attorney under California Probate Code sections 4000 to 4465, we may request proof of the power of attorney instead of the foregoing.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We may deny a request from an agent that does not submit proof you authorized them to act on your behalf.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Response Timing and Format
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      If you have an account with us, we will deliver our written response to that account. If you do not have an account with us, we will deliver our written response by mail or electronically, at your option.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Personal Information Opt-Out and Opt-In Rights
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      You may have the right to opt out of specific practices regarding your personal information at any time (the “<strong>right to opt-out</strong>”). If you are a parent or legal guardian and you give your permission for your child to register for the Site or the Application, you thereby agree to the terms relating to use of the Site or Application by your child.
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      You do not need to create an account with us to exercise your opt-out rights. We will only use personal information provided in an opt-out request to review and comply with the request.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Non-Discrimination
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We will not discriminate against you for exercising any of your rights under California Privacy Laws.
      </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Other California Privacy Rights
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      California's “Shine the Light” law (Civil Code Section § 1798.83) permits users of our Site that are California residents to request, once a year and free of charge, certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to <Link href="mailto:hello@OfferApproved.com">hello@OfferApproved.com</Link> or write us at: Legal Department, 1360 Post Oak Blvd, Suite 2400; Houston, TX 77056. </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Changes to Our Privacy Notice
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      We reserve the right to amend this privacy notice at our discretion and at any time. When we make changes to this privacy notice, we will post the updated notice on the Website and update the notice's effective date. </Typography>
      <Typography variant="h5" component="h5" mt={3} >
      Contact Information
      </Typography>
      <Typography variant="subtitle1" component="p" mt={3} >
      If you have any questions about this California Privacy Notice, our global privacy standards, or our handling of your personal information, you may contact us by email at <Link href="mailto:hello@OfferApproved.com">hello@OfferApproved.com</Link> or visit our OfferApproved Privacy Notice for additional contact details.</Typography>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
