import { Box, Stack, Typography } from "@mui/material";
import getPrice from "../../assets/get_price.png";
import "./style.css";

const BuyerSectionForMobile = () => {
  return (
    <Box className="innerBoxBuyer">
      <Stack gap={"1rem"} alignItems={"end"}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={"1rem"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Typography className="titleBuyer">GET YOUR PRICE</Typography>
          <img src={getPrice} alt="get price" className="priceImg" />
        </Box>
        <Typography className="descriptionBuyer">
          Don't Settle for the Listed Price. We give Buyers a transparent
          platform to let the market know what they're looking for and what
          they're willing to spend.
        </Typography>
      </Stack>
    </Box>
  );
};
export default BuyerSectionForMobile;
