import { Button, Container, Grid } from "@mui/material";
import "./style.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
export interface TicketProps {
  leftBgColor: string;
  leftColor: string;
  rightBgColor: string;
  rightColor: string;
  leftTitle: string;
  rightTitle: string;
  leftHoverBox?: React.ReactNode;
  rightHoverBox?: React.ReactNode;
  bgColor?: string;
}
const TicketServiceSection = ({
  leftHoverBox,
  rightHoverBox,
  leftBgColor,
  leftTitle,
  leftColor,
  rightBgColor,
  rightColor,
  rightTitle,
}: TicketProps) => {
  return (
    <Container
      maxWidth={false}
      sx={{
        maxWidth: "100%",
      }}
      style={{ padding: 0, marginBlock: "0" }}
    >
      <Grid
        container
        className="ticketBox"
        sx={{ display: { xs: "none", sm: "flex" } }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          className="leftGrid"
          style={{ background: leftBgColor }}
        >
          <Button
            className="box left"
            fullWidth
            endIcon={
              <ChevronRightIcon
                sx={{
                  color: leftColor,
                  borderRadius: "50%",
                  border: `3px solid ${leftColor}`,
                  fontSize: { xs: "20px !important", sm: "3rem !important" },
                  marginInlineStart: 3,
                }}
              />
            }
            sx={{
              color: leftColor,
              paddingLeft: "5rem",
            }}
          >
            {leftTitle}
          </Button>
          {leftHoverBox}
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          className="rightGrid"
          style={{ background: rightBgColor }}
        >
          <Button
            fullWidth
            className="box right"
            startIcon={
              <ChevronLeftIcon
                sx={{
                  color: rightColor,
                  borderRadius: "50%",
                  border: `3px solid ${rightColor}`,
                  fontSize: "3rem !important",
                  marginInlineEnd: 3,
                }}
              />
            }
            sx={{
              "& .MuiSvgIcon-root": {
                border: `3px solid ${rightColor}`,
              },
              color: rightColor,
              paddingRight: "5rem",
            }}
          >
            {rightTitle}
          </Button>
          {rightHoverBox}
        </Grid>
      </Grid>
    </Container>
  );
};
export default TicketServiceSection;
