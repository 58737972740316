import { Box, Typography } from "@mui/material";
import "./style.css";

const TicketHoverBox = () => {
  return (
    <Box className="rightHoverBox">
      <Box className="ticketInnerBox">
        <Typography className="rightTitle">
          MAXIMIZE YOUR SEASON TICKETS
        </Typography>
        <Typography className="rightSubTitle">
          As a community dedicated to premium tickets, we focus on trust,
          reliability, and exceptional service. Invited members can enjoy
          exclusive benefits and a secure marketplace.
        </Typography>
      </Box>
    </Box>
  );
};
export default TicketHoverBox;
