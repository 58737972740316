import React from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";

interface InputProps {
  name: string;
  label?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  helperText: string;
  error: boolean;
}
const NewTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  bordeRadius: "20px",
  opacity: "0.7",
  paddingTop: "10px",
  "&:focus-within .MuiInputLabel-root": {
    padding: "0",
    paddingBottom: "20px",
    color: "#000",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "20px",
    // border: "1px solid #353535",
    // backgroundColor: "#9b9b9b",
    fontFamily: `"Outfit", sans-serif`,
    color: "#000",
    fontWeight: "400",
    lineHeight: "37.8px",
    fontSize: "30px",
  },
  "& .MuiFormHelperText-root": {
    fontFamily: `"Outfit", sans-serif`,
    color: "#E98181",
    fontSize: "22px !important",
  },
  "& .MuiOutlinedInput-input": {
    padding: "24px 14px",
    "z-index": "1",
  },
  "& .MuiInputLabel-root": {
    fontFamily: `"Outfit", sans-serif`,
    color: "#000",
    padding: "9px",
    fontWeight: "400",
    lineHeight: "37.8px",
    fontSize: "30px",
  },
  "& fieldset": {
    backgroundColor: "#9b9b9b",
  },

  "&:focus-within fieldset": {
    borderColor: "#353535 !important",
  },
  "& .Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E98181",
  },

  "@media only screen and (max-width: 1440px)": {
    "& .MuiOutlinedInput-root": {
      lineHeight: "28.35px",
      fontSize: "22px",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "16px !important",
      marginRight: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "16px 20px",
    },
    "& .MuiInputLabel-root": {
      padding: "0px",
      lineHeight: "28.35px",
      fontSize: "22px",
    },
  },
  "@media only screen and (max-width: 767px)": {
    paddingTop: "5px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      lineHeight: "15px",
      fontSize: "12px",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "12px !important",
      marginRight: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 16px",
    },
    "& .MuiInputLabel-root": {
      padding: "0px",
      lineHeight: "10px",
      fontSize: "14px",
    },
  },
}));

const NewInputField = ({
  name,
  label,
  value,
  onChange,
  helperText,
  error,
}: InputProps) => {
  return (
    <NewTextField
      className="inputField"
      fullWidth
      variant="outlined"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      helperText={helperText}
      error={error}
    />
  );
};

export default NewInputField;
