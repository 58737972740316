import { Box, Typography } from "@mui/material";
import "./style.css";

const PremiumHoverBox = () => {
  return (
    <Box className="leftHoverBox">
      <Box className="innerPremiumBox">
        <Box className="textBox">
          <Typography className="leftTitle">DEDICATED CONCIERGE</Typography>
          <Typography className="rightSubTitle">
            Experience a white-glove service from start to finish,ensuring a
            seamless and premium ticket-buying and selling process.We'll be
            there to assist you with ticket selection,seating preferences, and
            any special requests.
          </Typography>
        </Box>
        <Box className="textBox">
          <Typography className="leftTitle">PREMIER ACCESS SWAP</Typography>
          <Typography className="rightSubTitle">
            Receive exclusive access to inventory from other premier members.
          </Typography>
        </Box>
        <Box className="textBox">
          <Typography className="leftTitle">
            CUSTOM CORPORATE PACKAGES
          </Typography>
          <Typography className="rightSubTitle">
            We work directly with organizations to optimize their corporate
            ticket portfolios. We'll find you extra seats when you need them -
            or make sure someone makes good use out of yours when you can't make
            it.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default PremiumHoverBox;
