import { Box, Stack, Typography } from "@mui/material";
import getPrice from "../../assets/get_price.png";
import "./style.css";

const BuyerSection = () => {
  return (
    <Box className="leftHoverBox">
      <Box className="buyerInnerBox">
        <Stack alignItems={"end"} gap={"1rem"}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={"2rem"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Typography className="buyerTitle">GET YOUR PRICE</Typography>
            <img src={getPrice} alt="get price" className="getPriceImg" />
          </Box>
          <Typography className="buyerDescription">
            Don't Settle for the Listed Price. We give Buyers a transparent
            platform to let the market know what they're looking for and what
            they're willing to spend.
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};
export default BuyerSection;
