import { Box, Button, Grid, Stack } from "@mui/material";
import ticketLogo from "../../assets/Vector.png";
import "./style.css";
import logo2 from "../../assets/Layer_1.png";
import twitterIcon from "../../assets/path1009.png";
import facebookIcon from "../../assets/Vector (2).png";
import linkedInIcon from "../../assets/Group 1.svg";
import instaIcon from "../../assets/insta-img.svg";
import { Link } from "react-router-dom";

interface FooterProps {
  rightSideImage: boolean;
}
const Footer = ({ rightSideImage }: FooterProps) => {
  return (
    <Grid container className="footerSection">
      <Grid item className="textWithLogo">
        <Stack justifyContent={"space-between"} height={"100%"}>
          <Stack>
            <img className="logo2" src={logo2} alt="logo" />
            <Box paddingTop={{ xs: 1, sm: 2 }} paddingLeft={{ xs: 0, sm: 5 }}>
              <a
                href="https://www.instagram.com/offerapproved/"
                rel="noreferrer"
                target="_blank"
              >
                <Button
                  className="iconBtn"
                  // sx={{
                  //   "& MuiButtonBase-root": {
                  //     padding: { xs: "0px !important", sm: "8px !important" },
                  //   },
                  // }}
                >
                  <img className="iconImg" src={instaIcon} alt="insta" />
                </Button>
              </a>
              <a
                href="https://www.facebook.com/offerapproved"
                rel="noreferrer"
                target="_blank"
              >
                <Button
                  className="iconBtn"
                  // sx={{
                  //   "& .MuiIconButton-root": {
                  //     padding: { xs: "4px", sm: "8px" },
                  //   },
                  // }}
                >
                  <img className="fbImg" src={facebookIcon} alt="facebook" />
                </Button>
              </a>
              <a
                href="https://linkedin.com/company/offerapproved/"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  className="iconBtn"
                  // sx={{
                  //   "& .MuiIconButton-root": {
                  //     padding: { xs: "4px", sm: "8px" },
                  //   },
                  // }}
                >
                  <img className="iconImg" src={linkedInIcon} alt="linkedin" />
                </Button>
              </a>
              <a
                href="https://x.com/offer_approved"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  className="iconBtn"
                  // sx={{
                  //   "& .MuiIconButton-root": {
                  //     padding: { xs: "4px", sm: "8px" },
                  //   },
                  // }}
                >
                  <img className="iconImg" src={twitterIcon} alt="twiter" />
                </Button>
              </a>
            </Box>
          </Stack>
          <Box>
            <span className="copyText">
              Copyright 2024 all rights reserved |
            </span>
            {"  "}
            <Link className="linkText" to={"/privacy-policy"} target="_blank">
              Privacy Policy
            </Link>
            <span className="copyText"> | </span>
            <Link
              className="linkText"
              to={"/terms-and-conditions"}
              target="_blank"
            >
              Terms of Use
            </Link>
          </Box>
        </Stack>
      </Grid>
      <Grid item>
        <img
          src={ticketLogo}
          alt="logo"
          className={rightSideImage ? "footerImg showImg" : "footerImg"}
          // style={{ display: rightSideImage ? "block" : "none" }}
        ></img>
      </Grid>
    </Grid>
  );
};
export default Footer;
