import { Button, Grid, Link, Typography } from "@mui/material";
import mainlogo2 from "../../assets/icons/mainlogo2.png";
import illustration from "../../assets/illustration.svg";
import BaseballSection from "../BaseballSection";
import FooterSection from "../FooterSection";
import "./index.css";

export const ThankYou = () => {
  return (
    <>
      <Grid
        container
        sx={{
          background: "#F1FFF8",
          paddingBlock: "0 4rem",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingBlock: { md: "2rem 4rem", xs: "1rem", lg: "2rem" },
            paddingInline: { xs: "1rem", md: "4rem" },
            cursor: "pointer",

            '& img': {
              width: { xs: "200px", md: 'auto', lg: "auto"},
              height: { xs: "40px", md: 'auto', lg: "auto"},
            }
          }}
          className="thank-you-offer-logo"
          onClick={() => (window.location.href = "/")}
        >
          <Link href="/">
            <img src={mainlogo2} alt="offer-logo" className="offer-logo" />
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            '& img': {
              width: { xs: "150px", md: 'auto', lg: "auto"},
              height: { xs: "150px", md: 'auto', lg: "auto"},
            }
          }}
        >
          <img src={illustration} alt="illustration" className="illustration" />
          <Typography
            variant="body1"
            className="thankyou-typography"
            sx={{
              marginBlock: "2rem",
              color: "#8e8e8e",
              textAlign: "center",
              fontSize: {xs:"16px",md:"22px"},
              fontStyle: "normal",
              fontFamily: "Optima, sans-serif",
              fontWeight: "400",
              lineHeight: {xs: "125%",md:"150%"},
              width: {xs: "80%",md: "30%"},
            }}
          >
            Thank you for reaching out to us! <br /> Your form has been
            successfully submitted. We appreciate your interest and will get
            back to you as soon as possible.
          </Typography>

          <Button
            className="thankyou-button-style"
            onClick={() => (window.location.href = "/")}
            sx={{
              borderRadius: "4px",
              background: "#11c4a3",
              width: {xs: "auto",md: "236px"},
              height: {xs: "auto", md: "76px"},
              paddingBlock: {xs: "0.25rem", md: "0"},
              paddingInline: {xs: "1rem", md: "0"},
              color: "#fff",
              textAlign: "center",
              fontSize: {xs: "14px", md:"18px"},
              fontStyle: "normal",
              fontWeight: {xs: "400", md: "500"},
              lineHeight: "40px",
              letterSpacing: "1.62px",
              textTransform: "uppercase",
              "&:hover, &:focus, &:focus-within": {
                background: "#11c4a3",
              },
            }}
          >
            Back to Home
          </Button>
        </Grid>
      </Grid>
      <BaseballSection />
      <FooterSection />
    </>
  );
};
