import { Container, Grid, Typography } from "@mui/material";
import ticket from "./../../assets/TicketGraphic.png";
import bgImg from "./../../assets/Frame_4.png";
import "./style.css";

const TicketSection = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        width: "100%",
      }}
      style={{ padding: 0, display: "flex", flexDirection: "column", gap: "0" }}
    >
      <Grid className="imageSection">
        <img className="bgImg" src={bgImg} alt="accept" />
      </Grid>
      <Grid container className="ticketSection">
        <Grid item sm={4} className="imgGrid">
          <img className="acceptImg" src={ticket} alt="accept" />
          {/* <img className="acceptImg" src={acceptAnOffer} alt="accept" />
        <img className="makeImg" src={makeAnOffer} alt="make" /> */}
        </Grid>
        <Grid item sm={8} className="textGrid">
          <Typography className="ticketText" color={"#0BEC81"}>
            Your Tickets
          </Typography>
          <Typography className="ticketText" color={"#ffffff"}>
            Your Terms
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
export default TicketSection;
