import { Box, Container, Grid, Typography } from "@mui/material";
import logo from "../../assets/Off-03.png";
import "./style.css";
const MemberSection = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        width: "100%",
      }}
      style={{ padding: 0 }}
    >
      <Grid container className="outerBox">
        <Grid item xs={4} sm={4} sx={{ paddingInline: { xs: 1, sm: 2 } }}>
          <Box className="memberBox">
            <Typography className="memberHeading">Member</Typography>
            <Typography className="memberText">
              All Offer Approved users have access to our reverse marketplace
              where buyers make guaranteed offers.
            </Typography>
            <Typography className="memberText">
              Transparent & competitive fee structure
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} sm={4} sx={{ paddingInline: { xs: 1, sm: 2 } }}>
          <Box className="preferredBox">
            <Typography className="preferedHeading">Preferred</Typography>
            <Typography className="preferedText">
              This tier is available to members that reach a high level of
              transaction activity.
            </Typography>
            <Typography className="preferedText">
              Reduced transaction fees
            </Typography>
            <Typography className="preferedText">
              Upgrades and special event access
            </Typography>
            <Typography className="preferedText">
              Priority on new app features
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} sm={4} sx={{ paddingInline: { xs: 1, sm: 2 } }}>
          <Box className="logoBox">
            <img className="logoImg" src={logo} alt="logo" />
            <Typography className="preferedText">
              Invite-only membership for premium ticket owners and corporations.
            </Typography>
            <Typography className="preferedText">
              Everything in PREFERRED +
            </Typography>
            <Typography className="preferedText">On call concierge</Typography>
            <Typography className="preferedText">
              Exclusive access to premium seats at every stadium we operate in
            </Typography>
            <Typography className="preferedText">
              Special credits on each transaction
            </Typography>
            <Typography className="preferedText">Custom dashboard</Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
export default MemberSection;
