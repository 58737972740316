import { AppBar } from "@mui/material";
import "./Header.css";
import logo from "../../assets/mainlogo2.png";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <AppBar position="sticky" color="default" className="appbar">
      <Link to={"/"} className="logo-link">
        {" "}
        <img src={logo} alt="App Logo" />
      </Link>
    </AppBar>
  );
};

export default Header;
