import { Box, Typography } from "@mui/material";
import "./style.css";

const TicketSectionForMobile = () => {
  return (
    <Box className="innerTicket">
      <Typography className="titleRight">
        MAXIMIZE YOUR SEASON TICKETS
      </Typography>
      <Typography className="subTitleRight">
        As a community dedicated to premium tickets, we focus on trust,
        reliability, and exceptional service. Invited members can enjoy
        exclusive benefits and a secure marketplace.
      </Typography>
    </Box>
  );
};
export default TicketSectionForMobile;
