import BuyerSection from "../BuyerSection";
import Footer from "../Footer";
import GeneralBetaForm from "../GeneralBetaForm";
import LogoSection from "../LogoSection";
import SellerSection from "../SellerSection";
import TicketSection from "../TicketSection";
import TicketServiceForMobile from "../TicketServiceForMobile";
import TicketServiceSection from "../TicketServiceSection";
import VideoSection from "../VideoSection";
import BuyerSectionForMobile from "../BuyerSectionForMobile";
import SellerSectionForMobile from "../SellerSectionForMobile";

const GeneralHomePage = () => {
  return (
    <>
      <LogoSection />
      <TicketSection />
      <TicketServiceSection
        leftBgColor={"#0BEC81"}
        leftColor={"#000000"}
        rightBgColor={"#333333"}
        rightColor={"#0BEC81"}
        leftTitle={"Empowering Sellers"}
        rightTitle={"Empowering Buyers"}
        leftHoverBox={<BuyerSection />}
        rightHoverBox={<SellerSection />}
      />
      <TicketServiceForMobile
        leftBgColor={"#0BEC81"}
        leftColor={"#000000"}
        rightBgColor={"#333333"}
        rightColor={"#fff"}
        leftTitle={"Empowering Sellers"}
        rightTitle={"Empowering Buyers"}
        leftHoverBox={<SellerSectionForMobile />}
        rightHoverBox={<BuyerSectionForMobile />}
      />
      <VideoSection />
      <GeneralBetaForm />
      <Footer rightSideImage={false} />
    </>
  );
};
export default GeneralHomePage;
