import "./index.css";
import { ToastContainer, Zoom } from "react-toastify";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
// import Index from "./pages";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditionsPage from "./pages/TermsAndConditions";
import AppPrivacyPolicy from "./pages/AppPrivacyPolicy";
import AppTermsAndConditions from "./pages/AppTermsAndConditions";
import { ThankYou } from "./components/ThankYou";
import NewLandingPage from "./pages/NewLandingPage";
import GeneralLandingPage from "./pages/GeneralLandingPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<GeneralLandingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditionsPage />}
        />
        <Route path="/policy" element={<AppPrivacyPolicy />} />
        <Route path="/terms" element={<AppTermsAndConditions />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/premier" element={<NewLandingPage />} />
        {/* <Route path="/general-landing-page" element={<GeneralLandingPage />} /> */}
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="light"
        transition={Zoom}
        limit={4}
      />
    </Router>
  );
}

export default App;
